<template>
<div>
    <header class="" style="padding-bottom:15px;">
        <div class="hdr ">
            <div class="hdr-content line-1">
                <div class="container pt-1 newContainer" style="max-width: 90%;">
                    <!-- <div class="hdr">
			<div class="hdr-content">
				<div class="container"> -->
					
                    <div class="row">
                        <div class="col-auto show-mobile" >
                            <div class="menu-toggle"> <a class="mobilemenu-toggle" ><i class="icon-menu" @click="sideMenu=true"></i></a>
                            </div>
                            <Sidebar :visible.sync="sideMenu" :baseZIndex="0" position="left" style="width:30%; overflow-x:hidden;">
                                <div class="dropdn-content account-drop" id="dropdnAccount" style="text-align: left">
                                        <div class="dropdn-content-block">
                                            <ul class="mx-1 mt-3">
                                                <li>
                                                    <a href="/" ><span class="pl-2"><i class="pi pi-home mr-1"></i>Home</span></a>    
                                                </li>
                                                <li>
                                                    <a href="/about"><span class="pl-2"><i class="pi pi-info-circle mr-1"></i>About Us</span></a>    
                                                </li>
                                                <li>
                                                    <a href="/contact" ><span class="pl-2"><i class="pi pi-phone mr-1"></i>Contact Us</span></a>    
                                                </li>
                                                <li>
                                                    <a href="/OurProduct"><span class="pl-2"><i class="pi pi-database mr-1"></i>Our Product</span></a>    
                                                </li>
                                                <li>
                                                    <a href="/comingSoon"><span class="pl-2"><i class="pi pi-server mr-1"></i>Our Service</span></a>    
                                                </li>
                                                <li>
                                                    <a href="/comingSoon" ><span class="pl-2"><i class="pi pi-sitemap mr-1"></i>Partners</span></a>    
                                                </li>
                                                <li>
                                                    <a href="/comingSoon"><span class="pl-2"><i class="pi pi-wifi mr-1"></i>Coverage</span></a>    
                                                </li>
                                                </ul>
                                            </div>
                                </div>
                                </Sidebar>
                        </div>
                        <div class="col-auto hdr-logo">
                            <a href="/dashboardPage" class="logo">
                                <!-- <img :src="imageLink + item.image" style="height: 50px !important; width:50px !important;"  > -->
                            </a>
                        </div>
                        <div class="hdr-nav hide-mobile nav-holder justify-content-center sticky-top">
                            <ul class="mmenu mmenu-js">
                                <li class="mmenu-item--simple hoverEffect fs-20"><a href="/" class="hoverEffect fs-20">Home</a>

                                </li>
                                <li class="mmenu-item--mega"><a href="/about" class="no-uppercase fs-20"><span>About
                                            Us</span></a>

                                </li>
                                <li class="mmenu-item--mega"><a href="/contact" class="no-uppercase fs-20"><span>Contact
                                            Us</span></a>

                                </li>
                                <li class="mmenu-item--simple"><a href="/OurProduct" class="fs-20">Our Product</a></li>
                                <li class="mmenu-item--simple"><a href="/comingSoon" class="fs-20">Our Service</a></li>
                                <li class="mmenu-item--simple"><a href="/comingSoon" class="fs-20">Partners</a></li>
                                <li class="mmenu-item--simple"><a href="/comingSoon" class="fs-20">Coverage</a></li>
                            </ul>
                        </div>
                        <div class="hdr-links-wrap col-auto ml-auto">
                            <div class="hdr-inline-link">
                                <!-- <div class="search_container_desktop"> -->
                                <!-- <div class="dropdn dropdn_search dropdn_fullwidth"> -->
                                <!-- <a href="#" class="dropdn-link  js-dropdn-link only-icon"><i class="pi pi-search " style="font-size: 1.5rem"></i><span class="dropdn-link-txt">Search</span></a> -->
                                <!-- <BaseInput type="text" class="form-control form-control--sm buttoncolor" placeholder="Jenny" /> -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <div class="dropdn dropdn_wishlist" v-if="isLoggedIn">
                                    <a href="/WishList" class="dropdn-link only-icon wishlist-link ">
                                        <i class="pi pi-heart" style="font-size: 1.7rem"></i><span class="dropdn-link-txt">Wishlist</span><span class="wishlist-qty">{{
                                                countofwishlist || 0 }}</span>
                                    </a>
                                </div>
                                <div class="dropdn dropdn_account dropdn_fullheight">
                                    <a @click="accountRights = true" class="dropdn-link js-dropdn-link js-dropdn-link only-icon" data-panel="#dropdnAccount"><i class="pi pi-user" style="font-size: 1.7rem"></i><span class="dropdn-link-txt">Account</span></a>
                                </div>
                                <Sidebar :visible.sync="accountRights" :baseZIndex="0" position="right" style="width:20%; overflow-x:hidden;">
                                    <div class="dropdn-content account-drop" id="dropdnAccount">
                                        <div class="dropdn-content-block">
                                            <ul class="mx-1">
                                                <li v-if="!isLoggedIn">
                                                    <a href="/login" @click="login()"><span class="pl-2">Sign In</span><i class="icon-login"></i></a>
                                                </li>
                                                <li v-else>
                                                    <a href="/login" @click="login()"><span class="pl-2">Log Out</span><i class="icon-login"></i></a>
                                                </li>
                                                <li v-if="!isLoggedIn"><a href="/registration"><span class="pl-2">Sign
                                                            Up</span><i class="icon-user2"></i></a></li>
                                                <!-- <li v-if="isLoggedIn"><a href="/checkout"><span class="pl-2">Checkout</span><i class="icon-card"></i></a></li> -->
                                                <li v-if="isLoggedIn"><a class="ml-0" href="/resetPassword"><span class="pl-2">Change Password</span><i class="icon-card"></i></a>
                                                </li>
                                                <li v-if="isLoggedIn"><a class="ml-0" href="/AccountDetails"><span class="pl-2">My Account</span><i class="icon-user2"></i></a>
                                                </li>
                                            </ul>
                                            <div class="dropdn-form-wrapper">

                                            </div>
                                        </div>
                                        <div class="drop-overlay js-dropdn-close"></div>
                                    </div>
                                </Sidebar>
                                <a v-if="isLoggedIn" class="dropdn-link js-dropdn-link minicart-link only-icon">
                                    <i class="pi pi-shopping-bag" style="font-size: 1.7rem"></i>
                                    <span class="minicart-qty" @click="getCartData()">{{ countofcartlist || 0 }}</span>
                                </a>
                                <Sidebar :visible.sync="cartRights" :baseZIndex="0" position="right" style="width:25%; overflow-x:hidden;">
                                    <div v-if="cartList.length > 0" :key="reloadCartComponent">
                                        <div class="minicart-prd row mt-2 ml-2" v-for="item of cartList" :key="item.id">
                                            <div class=" ">
                                                <a>
                                                    <img @error="replaceByDefault" class="img" :src="imageLink + item.image" style="height: 100px !important; width:100px !important;" alt></a>
                                            </div>
                                            <div class="minicart-prd-info col ml-2">
                                                <div class="minicart-prd-name">{{ item.name }}</div>
                                                <h2 class="minicart-prd-tag desc_width"><a href="#">{{ item.description }}</a></h2>
                                                <div class="minicart-prd-qty"><span class="minicart-prd-qty-label">Quantity:</span><span class="minicart-prd-qty-value">{{ item.quantity }}</span></div>
                                                <div class="minicart-prd-price prd-price">
                                                    <div class="price-new">{{ currencyName(item.price) }}</div>
                                                </div>
                                                <a href="#" class="cart-table-prd-remove d-flex justify-content-end mr-1" data-line-number="1"><i class="icon-recycle" @click="deleteCartData(item.id)"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="text-center pb-2">
                                        <b>No Data Found!</b>
                                    </div>

                                    <div class="minicart-drop-fixed js-hide-empty ml-2">
                                        <div class="loader-horizontal-sm js-loader-horizontal-sm"><span></span>
                                        </div>
                                        <div class="minicart-drop-total js-minicart-drop-total row no-gutters align-items-center">
                                            <div class="minicart-drop-total-txt col-auto heading-font">Subtotal</div>
                                            <div class="minicart-drop-total-price col" data-header-cart-total="">{{currencyName(subTotal)}}</div>
                                        </div>
                                        <div class="d-flex minicart-drop-actions w-75 mx-3" :class="{ 'disabled': cartList.length == 0 }">
                                            <a href="/cart" class="btn btn-hover-add-to-cart br-23"><i class="icon-basket"></i><span>Cart
                                                </span></a>
                                            <!-- <a href="/checkout" class="btn btn--md" style="background-color:#17c6aa;"><i class="icon-checkout"></i><span>Check
                                                out</span></a> -->
                                        </div>
                                        <!-- <ul class="payment-link mb-2">
                                        <li><i class="icon-amazon-logo"></i></li>
                                        <li><i class="icon-visa-pay-logo"></i></li>
                                        <li><i class="icon-skrill-logo"></i></li>
                                        <li><i class="icon-klarna-logo"></i></li>
                                        <li><i class="icon-paypal-logo"></i></li>
                                        <li><i class="icon-apple-pay-logo"></i></li>
                                    </ul> -->

                                    </div>
                                </Sidebar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoadingOverlay :active='isLoading' :loader='loader' />
    </header>
    
</div>
</template>

<script>
// import $ from 'jquery';
import currencyName from "../mixins/currency";
import onImageError from "@/comman/onImageError";
import {
    API_URL
} from "@/services/ApiServices"
import {
    viewlist_Cart,
    deleteCartlist
} from '@/api/CartApi'
import {
    view_wishList
} from '@/api/WishListApi'
import Sidebar from 'primevue/sidebar';
export default {
    components: {
        Sidebar
    },
    mixins: [onImageError, currencyName],
    data() {
        return {
            isLoading: false,
            cartRights: false,
            sideMenu:false,
            accountRights: false,
            cartList: [],
            subTotal: 0,
            countofwishlist: null,
            countofcartlist: null,
            isLoggedIn: '',
            loader: 'dots',
        }
    },
    created() {
        this.$root.$on('reloadCart', this.cartData);
        this.$root.$on('reloadWishlist', this.getwishlistdata);
    },
    methods: {
        deleteCartData(id) {
            this.$confirm.require({
                key: 'positionDialog',
                message: 'Do you want to delete this to cart?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.isLoading = true;
                    deleteCartlist(id).then((response) => {
                            if (response.status == 'success') {
                                this.$toastr.success(response.message);

                            }
                        })
                        .finally(() => {
                            this.isLoading = false;
                            this.getCartData();
                        })
                }
            });
        },
        // mobileMenu() {
        //     // window.THEME = {};
        //     let $body;
        //     let bodyScrollLock;
        //     let isMobile;
        //     let THEME;
        //     THEME.mobilemenu = {
        //         defaults: {
        //             mobilemenu: '.mobilemenu',
        //             toggleMenu: '.mobilemenu-toggle',
        //             search: '.search_container_desktop',
        //             searchOpenMobile: '.search_container_mobile',
        //             mobileMenuScroll: '.mobilemenu-scroll'
        //         },
        //         init: function init(options) {
        //             $.extend(this.defaults, options);

        //             var obj = {
        //                 $mobilemenu: $(this.defaults.mobilemenu),
        //                 $toggleMenu: $(this.defaults.toggleMenu),
        //                 $lang: $(this.defaults.lang),
        //                 $currency: $(this.defaults.currency),
        //                 $langMobile: $(this.defaults.langMobile),
        //                 $currencyMobile: $(this.defaults.currencyMobile),
        //                 $search: $(this.defaults.search),
        //                 $searchOpenMobile: $(this.defaults.searchOpenMobile)
        //             };
        //             $.extend(this.defaults, obj);
        //             if (this.defaults.$mobilemenu.length) {
        //                 this._handlers(this);
        //             }
        //         },
        //         _handlers: function _handlers() {
        //             var _ = this.defaults,
        //                 $scroll = $(_.$mobilemenu);
        //             $(_.mobileMenuScroll).perfectScrollbar();
        //             _.$toggleMenu.on('click.mobileMenu', function () {
        //                 _.$mobilemenu.toggleClass('active');
        //                 _.$toggleMenu.toggleClass('active');
        //                 $body.toggleClass('slidemenu-open');
        //                 if (isMobile) {
        //                     if ($body.hasClass('slidemenu-open')) {
        //                         bodyScrollLock.disableBodyScroll($scroll);
        //                     } else {
        //                         bodyScrollLock.clearAllBodyScrollLocks();
        //                     }
        //                 }
        //                 return false;
        //             });
        //             _.$mobilemenu.on('click.mobileMenu', function (e) {
        //                 if ($(e.target).is(_.$mobilemenu)) {
        //                     _.$mobilemenu.toggleClass('active');
        //                     _.$toggleMenu.toggleClass('active');
        //                     $body.toggleClass('slidemenu-open');
        //                     if (isMobile) bodyScrollLock.clearAllBodyScrollLocks();
        //                     e.preventDefault();
        //                 }
        //             });
        //         }
        //     };
        //     THEME.mobilemenu.init();
        // },
        getCartData() {
            this.isLoading = true;
            this.cartRights = true;
            let user_id = localStorage.getItem("userID")
            user_id = parseInt(user_id)
            viewlist_Cart(user_id).then((response) => {
                    this.cartList = response?.data?.product_id ? response.data.product_id : [];
                    this.subTotal = response?.data?.subTotal || 0;
                    this.countofcartlist = response?.data?.product_id.length || 0;
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        cartData() {
            let user_id = localStorage.getItem("userID")
            if (this.isLoggedIn === 'true') {
                this.isLoading = true;
                viewlist_Cart(user_id).then((response) => {

                        this.countofcartlist = response?.data?.product_id.length;
                        this.cartList = response?.data?.product_id;
                        this.subTotal = response?.data?.subTotal;
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
        login() {
            localStorage.clear();
        },
        async getwishlistdata() {
            this.isLoggedIn = localStorage.getItem('isLoggedIn');
            if (this.isLoggedIn === 'true') {
                let user_id = localStorage.getItem("userID")
                user_id = parseInt(user_id)
                await view_wishList(user_id).then((response) => {
                    this.countofwishlist = response?.data.length;
                    this.wishlistData = response?.data;
                })
            }
        }
    },
    mounted() {
        this.getwishlistdata();
        this.cartData();
        this.imageLink = API_URL + '/images/';
        this.isLoggedIn = localStorage.getItem('isLoggedIn');
        
    },
}
</script>

<style>
.newContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/* a {
    / color: black !important;/ text-decoration: none;
    background-color: transparent;
} */

.img {
    width: 95% !important;
    height: 120px;
}

.p-sidebar-content {
    overflow-x: hidden !important;
}

.minicart-drop-fixed {
    margin-top: auto;

    transform: translateZ(0);

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.btn,
.btn:active,
.btn:active:focus,
.btn:visited,
.btn:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
    background-color: #33cad5;
    box-shadow: none !important;
}

.btn--grey,
.btn--grey:active,
.btn--grey:active:focus,
.btn--grey:visited,
.btn--grey:focus {
    color: #282828;
    background-color: #efefef;
}

div .minicart-drop-actions :hover {
    background-color: black;
    color: white;
}

a.hoverEffect :hover {
    color: red !important
}

li.hoverEffect :hover {
    color: red;
}

.fs-20 {
    font-size: 17px !important;
}

.line-1 {
    border-bottom: 1px solid #dee2e6;
}

.br-23 {
    border-radius: 23px !important;
}

.btn-hover-add-to-cart {
    color: #fff !important;
    background-color: #17c6aa !important;
    border: none !important;
}

.btn-hover-add-to-cart:hover {
    background-color: #282828 !important;
    color: white !important;
    border: none !important;
}

.minicart-drop-total-txt {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 30px;
    color: #282828;
}

.minicart-drop-total-price {
    font-size: 20px !important;
    font-weight: 600;
    line-height: 30px;
    text-align: right;
    color: #282828;
}

.crs-pnt {
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
    /* You can adjust this to style the disabled appearance */
    pointer-events: none;
}

.align-center {
    align-items: center;
}

.desc_width {
    white-space: nowrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
