<template>
  <Button
    :label="label"
    :disabled="disabled"
    :class="[
      btntype !== 'primary' ? `p-button-${btntype}` : '',
      size !== '' ? `p-button-${size}` : '',
      btnClasses,
    ]"
    :icon="`
      ${
        loading
          ? 'pi pi-spin pi-spinner p-button-icon-left'
          : iconClass
          ? iconClass + ' p-button-icon-left'
          : ''
      }
    `"
    :badge="badgeValue"
    :badgeClass="badgeType !== 'primary' ? `p-badge-${badgeType}` : ''"
    :type="type"
    :iconPos="iconPos"
    @click="$emit('click', $event)"
  />
</template>

<script>
import Button from "primevue/button";
export default {
  components: {
    Button,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btntype: {
      type: String,
      default: "primary",
      validator: function (value) {
        return (
          [
            "primary",
            "secondary",
            "success",
            "info",
            "warning",
            "help",
            "danger",
          ].indexOf(value) !== -1
        );
      },
    },
    type: {
      type: String,
      default: "button",
      validator: function (value) {
        return ["button", "submit"].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: "md",
      validator: function (value) {
        return ["sm", "lg", "md"].indexOf(value) !== -1;
      },
    },
    iconClass: {
      type: String,
      default: "",
    },
    badgeValue: {
      type: String,
    },
    badgeType: {
      type: String,
      default: "primary",
      validator: function (value) {
        return (
          [
            "primary",
            "secondary",
            "success",
            "info",
            "warning",
            "help",
            "danger",
          ].indexOf(value) !== -1
        );
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: String,
      default: "",
    },
    iconPos: {
      type: String,
      default: "",
    },
  },
};
</script>
