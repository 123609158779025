import apiService from "@/services/ApiServices";

import {
    VIEWWISH_LIST,
    DELETE_WISHLIST
} from "@/api/ApiRoutes"

export const view_wishList = async (user_id) => {
const response = await apiService.get(`${VIEWWISH_LIST}/${user_id}`);
return response.data;
};

export const deleteWishlist = async(id) => {
    const response = await apiService.delete(`/${DELETE_WISHLIST}/${id}`);
    return response.data;
}