<template>
    <Dropdown
      :id="id"
      ref="inputField"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="inputValue"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :appendTo="appendTo"
      @change="$emit('change', $event)"
      
    />

    
</template>

<script>

import Dropdown from "primevue/dropdown";

export default {
  components: {
    Dropdown,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    //Dropdown label
    label: {
      type: String,
    },
    tooltipValue: {
      type: String,
    },
    // Dropdown reactive value
    value: {
      type: [String, Object, Array, Number],
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    iconShown: {
      type: Boolean,
      default: false,
    },

    //Dropdown Placeholder
    placeholder:{
      type:String,
      default:'',
    },

    // Dropdown disabled state
    disabled: {
      type: Boolean,
      default: false,
    },
    // Dropdown autofocus state
    autofocus: {
      type: Boolean,
      default: false,
    },
    //Dropdown options
    options: {
      type: Array,
      default: null,
    },

    //Dropdown option label
    optionLabel: {
      type: String,
      default: null,
    },
    //Dropdown option value
    optionValue: {
      type: String,
      default: null,
    },
    //Dropdown option diabled
    optionDisabled: {
      type: String,
      default: null,
    },
    appendTo: {
      type: String,
      default: "body",
    },
    
    //Dropdown display (chip or comma)
    display: {
      type: String,
      default: "comma",
    },
    

    
  },
  computed: {
    inputValue: {
      get: function () {
        
        return this.value;
      },
      set: function (newValue) {
  
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped>
.p-dropdown{
  border-radius: 23px !important;
  display: flex !important;
}
::v-deep .p-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d !important;
    font-size: 12px !important;
}

.p-disabled, .p-disabled * {
    cursor: default !important;
    pointer-events: none;
    user-select: none;
    background-color: #e9ecef !important;
}

::v-deep .p-dropdown-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 100% !important;
    text-overflow: ellipsis;
    cursor: pointer;
}
</style>