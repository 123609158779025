
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
//  export const API_URL = "https://e-com-qg6k.onrender.com"; //Old Link
 export const API_URL = "https://e-commerce-pharmacy-74f9.onrender.com";


const apiService = axios.create({
  baseURL: API_URL,
});

export const setupInterceptor = () => {
  apiService.interceptors.request.use(
    async (config) => {
      const newConfig = cloneDeep(config);
      const token = localStorage.getItem("token");
      if (token) {
        newConfig.headers.Authorization = token;
        newConfig.headers.Accept = "application/json";
      }
      return newConfig;
    },
    (error) => Promise.reject(error)
  );
};

export default apiService;
