export const LOGIN = 'api/user/login'
export const REGISTER_USER = 'api/user/registration';
export const GET_COUNTRY = 'api/user/country';
export const GET_STATE = 'api/user/state';
export const GET_CITY = 'api/user/city';

export const ADD_ADDRESS = 'api/billAddress/addAddress';
export const EDIT_ADDRESS = 'api/billAddress/editAddress';
export const VIEW_ADDRESS = 'api/billAddress/viewAddress';
export const DELETE_ADDRESS = 'api/billAddress/deleteAddress';

export const VIEW_PROFILE = 'api/user/viewProfile';
export const EDIT_PROFILE = 'api/user/editProfile';

export const VERIFY_EMAIL = 'api/user/verifyEmail';
export const VERIFY_OTP = 'api/user/verifyOtp';
export const UPDATE_PASSWORD = 'api/user/updatePassword';

export const RESET_PASSWORD = 'api/user/resetPassword';

export const BANNER_LIST = 'api/banner/listOfBanner';

// Address details

export const VIEW_BILL_ADDRESS = "/api/billAddress/viewAddress"

// Product Api Routes

export const VIEW_PRODUCT = "/api/product/viewProduct"

// Account details

export const VIEW_Profile = "/api/user/viewProfile"
export const LIST_PRODUCT = 'api/product/listOfProducts';
export const ADD_CART = 'api/cart/addToCart';

//WISHLIST Api Routes
export const ADD_WISHLIST  = 'api/wishlist/addWishlist';
export const VIEWWISH_LIST = 'api/wishlist/viewWishlist';
export const DELETE_WISHLIST = 'api/wishlist/deleteWishlist';

//Cart Api Routes
export const ADDLIST_CART = 'api/cart/viewCart';
export const DELETE_CART = 'api/cart/deleteCart';
export const EDIT_CART = 'api/cart/editCart';
export const VIEW_CART = 'api/cart/viewCart';
export const ORDER_HISTORY = 'api/order/orderHistory';
export const ORDER_VIEW = 'api/order/viewOrder';
export const ORDER_CANCEL = 'api/order/cancelOrder';
export const ADD_PRESCRIPTION = "api/cart/addPrescription"

// Checkout Billing Address
export const PLACE_ORDER = '/api/order/checkout';

// Product Count
export const Product_View_Count = '/api/product/productViewCount';


// checkout Api
export const Check_Out = '/api/order/checkout';
export const Add_Payment='api/payment/addPayment';