<template>
<div>
    <header-page class="sticky-top headerClass" />
    <div style="display:flex" >
        <slot />
    </div>
</div>
</template>

<script>
import HeaderPage from './HeaderPage.vue';
export default {
    components: {
        HeaderPage
    }
}
</script>
