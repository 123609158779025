import VueRouter from "vue-router";
// import ThankYou from '../components/ThankYou.vue'


export function Ecommerce(view) {
  return () => import(`@/views/${view}`);
}

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Ecommerce("LoginPage.vue"),
    props: { default: 'admin', title: 'vendor' },
    meta: {
      layout: "PublicLayout",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Ecommerce("info/ContactUs.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: Ecommerce("info/AboutUs.vue"),
  },
  {
    path: "/viewproduct/:id",
    name: "viewproduct",
    component: Ecommerce("product/viewProduct.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: Ecommerce("product/CartList.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: Ecommerce("RegistrationPage.vue"),
    meta: {
      layout: "PublicLayout",
    },
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: Ecommerce("changePassword.vue"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: Ecommerce("resetPassword.vue"),
  },
  {
    path: "/",
    name: "dashboardPage",
    component: Ecommerce("dashboard/dashboardPage.vue"),
    meta: {
      layout: "PublicLayout",
    },
  },
  {
    path: "/dashboard",
    name: "dashboardPage",
    component: Ecommerce("dashboard/dashboardPage.vue"),
    meta: {
      layout: "authLayout",
    },
  },
  {
    path: "/galleriaPage",
    name: "galleriaPage",
    component: Ecommerce("dashboard/galleriaPage.vue"),
  },
  {
    path: "/OurProduct",
    name: "OurProduct",
    component: Ecommerce("OurProduct/OurProduct.vue"),
  },
  {
    path: "/AccountDetails",
    name: "AccountDetails",
    component: Ecommerce("AccountDetails/AccountDetails.vue"),
  },
  {
    path: "/AddressDetails",
    name: "AddressDetails",
    component: Ecommerce("AddressDetails/AddressDetails.vue"),
  },
  {
    path: "/WishList",
    name: "WishList",
    component: Ecommerce("WishList/WishList.vue"),
  },
  // {
  //   path: "/new",
  //   name: "new1",
  //   component: () => import("../components/WishList/newPage.vue"),
  // },
  {
    path: "/OrderHistory",
    name: "OrderHistory",
    component: Ecommerce("OrderHistory/OrderHistory.vue"),
  },
  {
    path: "/OrderView/:id",
    name: "OrderView",
    component: Ecommerce("OrderHistory/OrderView.vue"),
  },
  {
    name:"checkout",
    path: "/checkout",
    component: () => import("../views/steps/CheckOutSteps.vue"),
    children: [
      {
        path: "/checkout/shippingAddress",
        name: "ShippingAddress",
        component: () => import("../views/steps/ShippingAddress.vue"),
      },
      {
        path: "",
        name: "BillingAddress",
        component: () => import("../views/steps/BillingAddress.vue"),
      },
      {
        path: "/checkout/deliveryMethod",
        name: "DeliveryMethod",
        component: () => import("../views/steps/DeliveryMethod.vue"),
      },
      {
        path: "/checkout/paymentMethod",
        name: "PaymentMethod",
        component: () => import("../views/steps/PaymentMethod.vue"),
      },
      {
        path: "/checkout/new",
        name: "new",
        component: () => import("../components/WishList/newPage.vue"),
      },
    ],
  },
  {
    path: "/comingSoon",
    name: "ComingSoon",
    component: Ecommerce("ComingSoon.vue"),
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: () => import("../components/ThankYou.vue"),
  }

];
const router = new VueRouter({
  mode: "history",
  routes,
});
router.afterEach((to, from) => {
  if (from.name == 'Login' && to.name == 'dashboardPage') {
    // router.go();
  }   
})
export default router;