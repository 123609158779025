import apiService from "@/services/ApiServices";

import {
    VIEW_CART,
    DELETE_CART,
    EDIT_CART,
    ADD_PRESCRIPTION
} from "@/api/ApiRoutes"


export const deleteCartlist = async (id) => {
    const response = await apiService.delete(`/${DELETE_CART}/${id}`);
    return response.data;
}

export const editCart = async (id, data) => {
    const response = await apiService.put(`/${EDIT_CART}/${id}`, data);
    return response.data;
}

export const viewlist_Cart = async (user_id) => {
    const response = await apiService.get(`${VIEW_CART}/${user_id}`);
    return response.data;
};


export const add_Prescription = async (data) => {
    const response = await apiService.post(`${ADD_PRESCRIPTION}`, data, {
        headers: {
            "Content-Type": `multipart/form-data`,
          },
    });
    return response.data;
};
