export default function (Vue) {
    Vue.toastr = {
      //success
      success: (message) => {
        return Vue.prototype.$toast.add({
          severity: "success",
          summary: "Success",
          detail: message,
          life: 2000,
        });
      },
      //info
      info: (message) => {
        return Vue.prototype.$toast.add({
          severity: "info",
          summary: "Read me",
          detail: message,
          life: 2000,
        });
      },
      //warn
      warn: (message) => {
        return Vue.prototype.$toast.add({
          severity: "warn",
          summary: "Warning",
          detail: message,
          life: 2000,
        });
      },
      //error
      error: (message) => {
        return Vue.prototype.$toast.add({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 2000,
        });
      },
  
      removeAllGroups: () => {
        return Vue.prototype.$toast.removeAllGroups();
      },
    };
  
    Object.defineProperties(Vue.prototype, {
      $toastr: {
        get: () => {
          return Vue.toastr;
        },
      },
    });
  }
  