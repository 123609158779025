<template>
<div style="width:100% !important">
    <component :is="layout" class="mainClass" />
    <router-view ></router-view>
    <FooterPage />
    <Toast  :baseZIndex="1020" />
    <ConfirmDialog></ConfirmDialog>
</div>
</template>

<script>
import Toast from "primevue/toast";
import AppLayoutDefault from './components/layout/authLayout.vue';
import ConfirmDialog from "primevue/confirmdialog";
import FooterPage from './components/layout/FooterPage.vue';
export default {
    name: 'app',
    components :{
      ConfirmDialog,
      FooterPage,
      Toast
    },
     data: () => ({
    layout: AppLayoutDefault
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {try {
          const component = await import(`@/components/layout/${route.meta.layout}.vue`)
          this.layout = component?.default || AppLayoutDefault;
        } catch (e) {
          this.layout = AppLayoutDefault;
        }
      }
    }
  }
}
</script>

<style>
.headerClass{
 background-color:#fff ;
}

body{
  margin: 0px !important;
  padding: 0px !important;
  height: 100vh !important;
}

</style>
