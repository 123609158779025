import Vue from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import VueRouter from 'vue-router';
import router from './routes/router';
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import vuelidate from 'vuelidate';
import Tooltip from 'primevue/tooltip';
import './assets/css/main.css'
import '../src/assets/fonts/icomoon/icons.css'
import TostService from 'primevue/toastservice';
import toastr from "@/utils/properties/toastMessage";
import {  setupInterceptor } from '@/services/ApiServices';
import ConfirmationService from 'primevue/confirmationservice';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

setupInterceptor();

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

Vue.component('LoadingOverlay',Loading)
Vue.use(TostService);
Vue.use(ConfirmationService);
Vue.use(toastr);
Vue.use(PrimeVue);
Vue.use(VueRouter);
Vue.directive('tooltip', Tooltip);

Vue.config.productionTip = false

new Vue({
  el: '#app',
  render: h => h(App),
  router,
});

const requireComponent = require.context(
  "./components",
  true,
  /Base[A-Z]\w+\.(vue|js)$/
 
);

requireComponent.keys().forEach((fileName) => {

  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(

      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  );
});


import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

Vue.use(vuelidate);